import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('form.store.generalInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'shelves',
      value: true,
      name: 'switch',
      label: i18n.t('form.coupon.online'),
      Object: {}
    }, {
      span: 24,
      prop: 'new_user_only',
      value: true,
      name: 'switch',
      label: i18n.t('form.coupon.onlyNew'),
      Object: {}
    }, {
      span: 8,
      prop: 'event_name',
      name: 'input',
      label: i18n.t('form.coupon.eventName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'start_date',
      name: 'date',
      label: i18n.t('form.coupon.codeStartDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {}
    }, {
      span: 8,
      prop: 'end_date',
      name: 'date',
      label: i18n.t('form.coupon.endDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {}
    }, {
      span: 8,
      prop: 'maximum_use_count',
      name: 'input',
      type: 'number',
      label: i18n.t('form.coupon.maximum'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {}
    }, {
      span: 8,
      prop: 'same_user_redemption_limit',
      name: 'input',
      type: 'number',
      label: i18n.t('form.coupon.redemptionLimit'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {}
    }, {
      span: 8,
      prop: 'voucher_type',
      name: 'select',
      options: [],
      label: i18n.t('form.coupon.voucherType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        label: 'label'
      }
    }, {
      span: 8,
      prop: 'base_price',
      name: 'group-radio',
      value: 'selling_price',
      options: [],
      label: i18n.t('form.coupon.basePrice'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        visible: 'voucher_type',
        visibleValue: 'discount_voucher',
        filterable: true,
        label: 'label',
        extraText: i18n.t('form.coupon.basePriceText')
      }
    }, {
      span: 8,
      prop: 'coupon_value_f',
      name: 'input',
      type: 'number',
      label: i18n.t('form.coupon.voucherValue'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'voucher_type',
        visibleValue: 'value_voucher'
      }
    }, {
      span: 8,
      prop: 'coupon_discount',
      name: 'input',
      type: 'number',
      label: i18n.t('form.coupon.couponDiscout'),
      placeholder: i18n.t('placeholder.discount'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'voucher_type',
        visibleValue: 'discount_voucher'
      }
    }, {
      span: 8,
      prop: 'max_discount_f',
      name: 'input',
      type: 'number',
      label: i18n.t('form.coupon.maxDiscount'),
      placeholder: i18n.t('placeholder.discount'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'voucher_type',
        visibleValue: 'discount_voucher,free_delivery_voucher'
      }
    }, {
      span: 8,
      prop: 'code_generation',
      name: 'select',
      options: [],
      label: i18n.t('form.coupon.codeGeneration'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        label: 'label',
        disabled: true,
        nextChange: true
      }
    }, {
      span: 8,
      prop: 'voucher_code',
      name: 'input',
      label: i18n.t('form.coupon.voucherCode'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        disabled: true,
        visible: 'code_generation',
        visibleValue: 'manual_edit'
      }
    }, {
      span: 8,
      prop: 'number_of_codes',
      name: 'input',
      label: i18n.t('form.coupon.numberOfCodes'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        disabled: true,
        visible: 'code_generation',
        visibleValue: 'random_generation'
      }
    }, {
      span: 8,
      prop: 'applied_target_type',
      name: 'select',
      options: [],
      label: i18n.t('form.coupon.appliedTargetType'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        filterable: true,
        label: 'label',
        nextChange: true,
        nextChangeProp: 'applied_target_type',
        disabled: true
      }
    }, {
      span: 8,
      prop: 'shop_ids',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.coupon.chooseShop'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        multiple: true,
        'collapse-tags': true,
        key: 'id',
        value: 'id',
        visible: 'applied_target_type',
        visibleValue: 'shop',
        nextChange: true,
        nextChangeProp: 'shop_ids'
      }
    }, {
      span: 8,
      prop: 'coupon_ids',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.coupon.chooseOfflineCoupon'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        multiple: true,
        'collapse-tags': true,
        key: 'id',
        value: 'id',
        isAllValue: 0,
        isAll: true,
        visible: 'applied_target_type',
        visibleValue: 'coupon'
      }
    }, {
      span: 8,
      prop: 'shop_id',
      name: 'select',
      options: [],
      label: i18n.t('form.coupon.chooseShop'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        key: 'id',
        value: 'id',
        visible: 'applied_target_type',
        visibleValue: 'product',
        nextChange: true,
        nextChangeProp: 'shop_id',
        disabled: true
      }
    }, {
      span: 8,
      prop: 'apply_to',
      name: 'select',
      options: [],
      label: i18n.t('form.coupon.applyTo'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        label: 'label',
        filterable: true,
        visible: 'applied_target_type',
        visibleValue: 'product',
        nextChange: true,
        nextChangeProp: 'apply_to',
        disabled: true
      }
    }, {
      span: 8,
      prop: 'site_id',
      name: 'select',
      options: [],
      label: i18n.t('form.coupon.chooseSite'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        disabled: true,
        filterable: true,
        key: 'id',
        value: 'id',
        visible: 'applied_target_type',
        visibleValue: 'ticket_package',
        nextChange: true,
        nextChangeProp: 'site_id'
      }
    }, {
      span: 8,
      prop: 'ticket_apply_to',
      name: 'select',
      options: [],
      label: i18n.t('form.coupon.ticketApplyTo'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        disabled: true,
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        label: 'label',
        filterable: true,
        visible: 'applied_target_type',
        visibleValue: 'ticket_package',
        nextChange: true,
        nextChangeProp: 'ticket_apply_to'
      }
    }, {
      span: 8,
      prop: 'ticket_package_ids',
      name: 'select',
      options: [],
      label: i18n.t('form.coupon.chooseTicket'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        multiple: true,
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        label: 'name',
        filterable: true,
        visible: 'ticket_apply_to',
        visibleValue: 'selected_tickets',
        key: 'id',
        value: 'id'
        // nextChange: true,
        // nextChangeProp: 'ticket_package_ids'
      }
    }, {
      span: 8,
      prop: 'ticket_type_ids',
      name: 'select',
      options: [],
      label: i18n.t('form.coupon.chooseTicket'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        multiple: true,
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        label: 'name',
        filterable: true,
        visible: 'applied_target_type',
        visibleValue: 'ticket_type',
        key: 'id',
        value: 'id'
        // nextChange: true,
        // nextChangeProp: 'ticket_package_ids'
      }
    }, {
      span: 8,
      prop: 'third_party_voucher_i_d',
      name: 'input',
      label: i18n.t('form.coupon.thirdPartyVoucherId'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'mini_spend',
      name: 'input',
      type: 'number',
      label: i18n.t('form.coupon.miniSpend'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.coupon.miniSpend') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'description',
      name: 'input',
      value: '',
      type: 'textarea',
      label: i18n.t('form.common.description'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        client: 'fooyo',
        rows: 4
      }
    }]
  }]
}
