// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getOnlineVoucherEventEditDetails, getOnlineVoucherEventConfigurations, getShopList, getShopProduct, onlineVoucherEventEdit, getSimplelist, getSiteSimpList, getTicketPackageSimpList, getTicketTypeSimpList } from '@/services/coupon.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  name: 'onlineVoucherEdit',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      applyToOptions: [
        {
          label: this.$t('form.coupon.entireShop'),
          value: 'entire_shop'
        },
        {
          label: this.$t('form.coupon.selectedProducts'),
          value: 'selected_products'
        }
      ],
      ticketApplyToOptions: [
        {
          label: this.$t('form.coupon.entireTicket'),
          value: 'entire_tickets'
        },
        {
          label: this.$t('form.coupon.selectedTicket'),
          value: 'selected_tickets'
        }
      ],
      site_id: ''
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getOnlineVoucherEventConfigurations().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[7].options = res.data.voucher_types
          this.formData[0].createDateItem[15].options = res.data.applied_target_types
          this.formData[0].createDateItem[12].options = res.data.code_generations
          this.formData[0].createDateItem[19].options = this.applyToOptions
          this.formData[0].createDateItem[8].options = res.data.base_prices
        }
      })
      getShopList().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[16].options = res.data.shops
          this.formData[0].createDateItem[18].options = res.data.shops
        }
      })
      getOnlineVoucherEventEditDetails(this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.modifyData = res.data.online_voucher_code_event
          if (this.modifyData.applied_target_type === 'product') {
            if (this.modifyData.shop_item_ids.length === 1 && this.modifyData.shop_item_ids[0] === 0) {
              this.modifyData.apply_to = this.applyToOptions[0].value
            } else {
              this.modifyData.apply_to = this.applyToOptions[1].value
            }
            // this.getProduct(this.modifyData.shop_item_ids)
          }
          if (this.modifyData.applied_target_type === 'coupon') {
            this.getOfflineVoucherList()
          }

          if (this.modifyData.applied_target_type === 'ticket_package') {
            this.getSites()
            if (this.modifyData.ticket_package_ids.length === 1 && this.modifyData.ticket_package_ids[0] === 0) {
              this.modifyData.ticket_apply_to = this.ticketApplyToOptions[0].value
            } else {
              this.site_id = this.modifyData.site_id
              this.getTicketPackage()
              this.modifyData.ticket_apply_to = this.ticketApplyToOptions[1].value
            }
          }

          if (this.modifyData.applied_target_type === 'ticket_type') {
            // this.getSites()
            // if (this.modifyData.ticket_type_ids.length === 1 && this.modifyData.ticket_type_ids[0] === 0) {
            //   this.modifyData.ticket_apply_to = this.ticketApplyToOptions[0].value
            // } else {
            //   this.site_id = this.modifyData.site_id
            //   this.getTicketType()
            //   this.modifyData.ticket_apply_to = this.ticketApplyToOptions[1].value
            // }
            // this.modifyData.ticket_package_ids = this.modifyData.ticket_type_ids
            this.getTicketType()
          }
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop) {
      if (prop === 'applied_target_type') {
        if (data === 'product') {
          // this.getProduct()
        }
        if (data === 'coupon') {
          this.getOfflineVoucherList()
        }
        if (data === 'ticket_package') {
          this.getSites()
        }
      }
      if (prop === 'ticket_apply_to') {
        if (data === 'selected_tickets') {
          this.getTicketPackage()
        }
      }
      if (prop === 'site_id') {
        this.site_id = data
      }
      if (prop === 'shop_ids') {
        // this.getProduct(data)
      }
    },
    getProduct (shop_id) {
      const params = {
        shop_id,
        all: true,
        only_name: true
      }
      getShopProduct(params).then(res => {
        res.data.shop_items.unshift({ id: 0, name: this.$t('form.coupon.all') })
        this.formData[0].createDateItem[12].options = res.data.shop_items
        console.log(this.formData)
      })
    },
    getOfflineVoucherList () {
      getSimplelist().then(res => {
        res.data.offline_coupons.unshift({ id: 0, name: this.$t('form.coupon.all') })
        this.formData[0].createDateItem[17].options = res.data.offline_coupons
      })
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      // if (obj.applied_target_type === 'ticket_type') {
      //   obj.ticket_type_ids = obj.ticket_package_ids
      //   delete obj.ticket_package_ids
      // }
      if (obj.ticket_apply_to === 'entire_tickets') {
        obj.ticket_package_ids = [0]
        // if (obj.applied_target_type === 'ticket_type') {
        //   obj.ticket_type_ids = obj.ticket_package_ids
        //   delete obj.ticket_package_ids
        // }
      }
      delete obj.apply_to
      delete obj.shop_id
      delete obj.ticket_apply_to
      const params = {
        online_voucher_code_event: obj
      }
      onlineVoucherEventEdit(params, this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'onlineVoucherCodeList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    getSites () {
      getSiteSimpList().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[20].options = res.data.sites
          this.formData[0].createDateItem[21].options = this.ticketApplyToOptions
        }
      })
    },
    getTicketPackage () {
      const params = {
        site_id: this.site_id
      }
      getTicketPackageSimpList(params).then(res => {
        if (res.status === 200) {
          console.log(res.data.ticket_packages)
          this.formData[0].createDateItem[22].options = res.data.ticket_packages
        }
      })
    },
    getTicketType () {
      // const params = {
      //   site_id: this.site_id
      // }
      getTicketTypeSimpList().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[23].options = res.data.ticket_types
        }
      })
    }
  }
}
